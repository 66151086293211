import React from 'react'
import Adminpage from '../Components/Adminpage'

const AdminPanel = () => {
  return (
    <div>
      <Adminpage/>
    </div>
  )
}

export default AdminPanel
