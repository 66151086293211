// src/pages/AdminPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.css'; // Import external CSS file

const AdminPage = () => {
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login'); // Redirect to login if not authenticated
    }

    const fetchMessages = async () => {
      try {
        const response = await axios.get('http://localhost:4000/api/messages', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMessages(response.data);
      } catch (error) {
        console.log('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className="admin-container">
      <h2>Admin Panel</h2>
      <h3>Messages</h3>
      <ul>
        {messages.length > 0 ? (
          messages.map((message) => (
            <li key={message._id}>{message.name}: {message.textarea}</li>
          ))
        ) : (
          <p>No messages found.</p>
        )}
      </ul>
      <button className="logout-btn" onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default AdminPage;
