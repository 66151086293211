import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="top-footer-sec">
        <div className="links">
          <h2>AUA Innovation's</h2>
        </div>

        <div className="contact-link">
          <h2>Contact Us</h2>
          <div className="social-links">
            <a href="https://www.facebook.com/profile.php?id=61569124254801&is_tour_completed" target="_blank" rel="noopener noreferrer">
              <i className="bx bxl-facebook"></i>
            </a>
            <a href="https://www.instagram.com/auainnovations/" target="_blank" rel="noopener noreferrer">
              <i className="bx bxl-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/aua-innovations/" target="_blank" rel="noopener noreferrer"><i className='bx bxl-linkedin'></i></a>
            <a href="https://wa.me/+923137102698" target="_blank" rel="noopener noreferrer">
              <i className="bx bxl-whatsapp"></i>
            </a>
          </div>
        </div>

        <div className="map">
          <h2>Location</h2>
            <h2>Faisalabad,Pakistan</h2>
        </div>
      </div>
      <div className='footerlast-sec'>
       
           
            <p>Copyright &copy; 2024 by AUA Innovation's. | All Rights Reserved.</p>
         
      </div>
    </footer>
  );
};

export default Footer;
