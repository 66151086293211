import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Curser from './Components/Curser';
import HeaderComponent from './Components/Header';
import Footer from './Components/Footer';
import Contactus from './pages/Contactus';
import Servicespage from './pages/Servicespage';
import Aboutpage from './pages/Aboutpage';
import NotFound from './pages/NotFound';
import ScrollToTop from './Components/ScrollToTop';
// import Login from './pages/Login';
// import Signup from './pages/Signup';
import AdminPanel from './pages/AdminPanel';
import PrivateRoute from './Components/PrivateRoute';
import Loder from './Components/Loder';

function App() {
  return (
    <Router>
      <>
      <Loder/>
        <ScrollToTop />
        <Curser />
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/services" element={<Servicespage />} />
          <Route path="/about" element={<Aboutpage />} />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminPanel />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </>
    </Router>
  );
}

export default App;
