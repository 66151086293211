import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './abp.css';

const AUAInnovations = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/contactus'); // Navigate to the Contact Us page
    window.scrollTo(0, 0); // Scroll to the top after navigation
  };

  useEffect(() => {
    // Ensure the page starts at the top when the component loads
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className='abcp'>
    <div className="aua-container">
    
  
      <main className="aua-main">
        <div className="aua-card">
          <h2>About Us</h2>
          <p>
            At <span className="aua-highlight">AUA Innovations</span>, we believe in transforming ideas into impactful digital solutions. Our mission is to help businesses excel by providing a full spectrum of digital marketing services, combining creativity with advanced technology to deliver measurable results.
          </p>
          <p>
            With a dedicated team of experts, we specialize in creating personalized strategies tailored to meet the unique needs of each client. Whether it’s enhancing your online visibility, increasing customer engagement, or driving sales, AUA Innovations is your trusted partner in the digital realm.
          </p>
        </div>

        <div className="aua-card">
          <h2>Our Mission</h2>
          <p>
            At the heart of AUA Innovations lies a commitment to innovation and excellence. Our mission is to:
          </p>
          <ul>
            <li>Provide customized digital marketing solutions that align with your brand identity.</li>
            <li>Help businesses thrive in a competitive online landscape.</li>
            <li>Leverage data-driven insights to optimize performance and ROI.</li>
            <li>Foster long-term partnerships based on trust and success.</li>
          </ul>
          <p>
            We aim to be the catalyst for your business growth, ensuring your success is our top priority.
          </p>
        </div>

        <div className="aua-card">
          <h2>Why Choose Us?</h2>
          <p>
            Partnering with AUA Innovations means choosing a team that is passionate, skilled, and results-oriented. Here’s why we stand out:
          </p>
          <ul>
            <li><strong>Expertise:</strong> Our team comprises industry professionals with a proven track record of success.</li>
            <li><strong>Innovation:</strong> We stay ahead of trends to ensure your strategies are always cutting-edge.</li>
            <li><strong>Customization:</strong> Every solution we offer is uniquely tailored to your business goals.</li>
            <li><strong>Support:</strong> We provide continuous guidance and support, even after project completion.</li>
          </ul>
          <p>
            Let us help you achieve your digital ambitions with strategies that deliver.
          </p>
        </div>

        <div className="aua-card aua-contact">
          <h2>Get in Touch</h2>
          <p>
            Ready to take your business to the next level? Contact us today and let’s create something extraordinary together.
          </p>
          <p>Email: <a href="mailto:info@auainnovations.com">info@auainnovations.com</a></p>
          <p>Phone: +923137102698</p>
          <p>Phone: +923107860717</p>
          <p>Phone: +923277210003</p>
          <p>Follow us on social media for the latest updates and insights:</p>
          <div className="social-links">
            <a href="https://www.facebook.com/profile.php?id=61569124254801&is_tour_completed" target="_blank" rel="noopener noreferrer">Facebook</a> |
            <a href="https://www.linkedin.com/company/aua-innovations/" target="_blank" rel="noopener noreferrer">Linkedin</a> |
            <a href="https://www.instagram.com/auainnovations/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
          <button className="aua-button" onClick={handleNavigation}>Contact Us</button>
        </div>
      </main>

     
    </div>
    </section>
  );
};

export default AUAInnovations;
