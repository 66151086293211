import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './contact.css'; // Ensure the path is correct

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    textarea: '',
  });
  const [message, setMessage] = useState(''); // For dynamic success/error messages
  const [isSubmitting, setIsSubmitting] = useState(false); // For loader/spinner

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Show loader

    try {
      await emailjs.send(
        'service_r7dcopr', // Replace with your EmailJS service ID
        'template_o6mrtg9', // Replace with your EmailJS template ID
        {
          name: formData.name,
          email: formData.email,
          message: formData.textarea, // Or the correct field for the message
        },
        'TgdsMTWfTxiOmqvlj' // Replace with your EmailJS public key (API key)
      );

      setMessage('Message sent successfully!');
      setFormData({ name: '', email: '', textarea: '' }); // Clear form fields
    } catch (error) {
      setMessage('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false); // Hide loader
    }
  };

  return (
    <section className="contact-main-div" id="contact">
      <div className="container">
        <div className="content">
          <div className="left-side">
            <div className="address details">
              <i className="bx bx-map"></i>
              <div className="topic">Address</div>
              <div className="text-one">Faisalabad,</div>
              <div className="text-two">Pakistan</div>
            </div>
            <div className="phone details">
              <i className="bx bx-phone-call"></i>
              <div className="topic">Phone</div>
              <div className="text-one">+923137102698</div>
              <div className="text-two">+923107860717</div>
              <div className="text-two">+923277210003</div>
            </div>
            <div className="email details">
              <i className="bx bx-envelope"></i>
              <div className="topic">Email</div>
              <div className="text-one"><a href="mailto:info@auainnovations.com">auainnovations@gmail.com</a></div>
              <div className="text-two"><a href="mailto:info@auainnovations.com">info@auainnovations.com</a></div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">Send us a message</div>
            <form onSubmit={handleSubmit}>
              <div className="input-box">
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-box">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-box message-box">
                <textarea
                  name="textarea"
                  placeholder="Your Message"
                  value={formData.textarea}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="button">
                <button type="submit" className="send-btn" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <span className="spinner"></span> // Loader/spinner
                  ) : (
                    'Send Now'
                  )}
                </button>
              </div>
            </form>
            {message && (
              <p className={`message ${message.includes('successfully') ? 'success' : 'error'}`}>
                {message}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

