
import React from 'react';


import './ServicesC.css';
import img77 from "../img/1-5.png"
import img78 from "../img/2-5.png"
import img79 from "../img/3-4.png"
import img80 from "../img/5-6.jpg"
import img81 from "../img/7-8.png"
import img82 from "../img/6-2.png"
import img83 from "../img/7-2.png"
import img84 from "../img/8-2.png"
import img85 from "../img/1212.jpg"
import img70 from "../img/49755.jpg"

const ServicespageC = () => {
  
  return (
    <div>
      
      <div className='serv68'>
        <div className='main-top-serv'>
                 <div className='heading'>
                  <h1>
                  SERVICES
                  </h1>
                 </div>
        </div>
        <div className='serv-content'>
        <h2 className="heading">
      Our<span> Services</span>
    </h2>
    <div className='services-show'>
    <div className='head'><h1> Graphic Design</h1></div>
        <div className='services-img'>
              <img src={img77} alt='graphic design'/>
        </div>
        <div className='services-content99'>
     
           <p>
                      
<li>                   Logo Design.                 </li> 
          
          <li>                  Branding.                </li> 
          <li>                      Illustration.                </li> 
          <li>             Infographics.              </li> 
          <li>                  Print Design.
          </li> 
          <li>                  Photo Editing.
          </li> 
          <li>                 Menu Design & Price Lists.                </li> 
          <li>                 Flyer Designs.                </li> 
          <li>                  Branding.                </li> 
          <li>                 Business Cards & Email Signatures.                </li> 
          <li>           Social Media Posts.               </li> 
          <li>             YouTube Thumbnails.           </li> 
          <li>       Presentations.          </li> 
    
         
           

      





           </p>
           </div>
           </div>


           <div className='services-show'>
           <div className='head'> <h1>Website Development</h1></div>
        <div className='services-img'>
              <img src={img78} alt='graphic design'/>
        </div>
        <div className='services-content99'>
     
           <p>
            <li>       Business Website.     </li>
            <li>   Portfolio Website.    </li>
            <li>   Blog Website.     </li>
            <li>   E-Commerce.    </li>
            <li>   Website Customization.    </li>
            <li>  Shopify Store.     </li>
            <li>  Wordpress Development.     </li>
            <li> MERN Stack Development.   </li>
        
      
           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'> <h1>Content Writting</h1></div>
        <div className='services-img'>
              <img src={img79} alt='graphic design'/>
        </div>
        <div className='services-content99'>
          
           <p>
<li>                 Keyword Research.                   </li> 
<li>                  SEO Optimize article.                 </li> 
          
<li>               Proper ON-Page SEO.                </li> 
          
<li>                 Article Publishing.                 </li> 
          
<li>                  Pictures According to Content.                 </li> 

          
         





           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'> <h1>AutoCAD</h1></div>
        <div className='services-img'>
              <img src={img70} alt='graphic design'/>
        </div>
        <div className='services-content99'>
          
           <p>
            <li>     2D Design.   </li>
            <li>   3D Design. </li>
            <li>   Elevation. </li>
            <li>  Working Design. </li>
            <li>   Moldings Design.
            </li>
       



           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>SEO (Search Engine optimization)</h1></div>
        <div className='services-img'>
              <img src={img80} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
            <li>Guest Posting.
            </li>
            <li>Backlinks.   </li>
            <li>  Keyword Research.   </li>
            <li> Website Audit.  </li>
            <li>Competitor Analysis.  </li>
            <li>  ON-Page SEO.  </li>
            <li>   Off-Page SEO.    </li>
            <li>Technical SEO.   </li>
 

           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Social Media Handlling </h1></div>
        <div className='services-img'>
              <img src={img81} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
            <li>           Facebook Handlling.   </li>
            <li>  linkedin handling.  </li>
            <li>   pinterest Handling.   </li>
            <li>  Instagram Handling.  </li>
          
   



           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Lead Generation  </h1></div>
        <div className='services-img'>
              <img src={img82} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
          <li>     B2B Lead Generation.  </li>
          <li>     B2C Lead Generation.   </li>
        
        
          

           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Data Scraping  </h1></div>
        <div className='services-img'>
              <img src={img83} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
            <li>Web Scraping.     </li>
            <li>   Google Map Data Scraping.  </li>
            <li>Data Scraping from Business Directories.   </li>
          
           
 

           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Cold Emailing </h1></div>
        <div className='services-img'>
              <img src={img84} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
            <li>     Set up COld E-mail.   </li>
            <li> DNS Management.   </li>
            <li> Configure MX,SPF,DKIM,and DMARC.   </li>
            <li> Connect Email accounts with AI.   </li>
            <li> Enable Email Forwarding.   </li>
            <li>   Email Warmup.    </li>
            <li> Campaign Management.     </li>
            <li>  Cold Email Infrastructure.   </li>
        
      
           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Business Management</h1></div>
        <div className='services-img'>
              <img src={img85} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
            <li>  Administration. </li>
            <li> Operational Management.  </li>
            <li>Prouncement Handeling.  </li>
            <li>Accounts Management.  </li>
            <li> Invoice Generation.  </li>
            <li> Book Keeping.  </li>
       
           </p>
           </div>
           </div>















        </div>
      </div>
    </div>
  )
}

export default ServicespageC
