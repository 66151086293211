import React, { useState, useEffect } from 'react';
import img111 from "../img/logoaua.png";
import './loder.css'; // Import external CSS

const Loder = () => {
  const [showLoader, setShowLoader] = useState(true);

  // Hide the loader after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  return (
    <>
      {showLoader && (
        <div className='mainloder'>
        <div className="loader">
          <div className="box">
            <div className="logo">
              <img src={img111} alt="Logo" className="logo-image" />
            </div>
          </div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
        </div>
        </div>
      )}
    </>
  );
};

export default Loder;
