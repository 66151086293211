import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function Herosection() {
  const navigate = useNavigate();

  const handleHireMeClick = () => {
    navigate('/contactus'); // Navigate to Contact Us page
  };

  const handleLetsTalkClick = () => {
    const whatsappNumber = "+923137102698";
    const whatsappMessage = "Hello, I would like to discuss...";
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank'); // Open WhatsApp in a new tab
  };

  return (
    <div>
      <section className="home" id="home">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0, transition: { delay: 0.5, duration: 0.5 } }}
          viewport={{ once: false }}
          className="home-content"
        >
          <h1>Welcome To <span></span></h1>
          <div className="text-animate">
            <h3>AUA INNOVATION'S</h3>
          </div>
          <p>At AUA Innovation's, we're dedicated to excellence in every facet of our business. Our commitment is to provide top-tier services and innovative solutions that meet the diverse needs of our clients. We believe in fostering a culture of quality, reliability, and continuous improvement, aiming to be a trusted partner in your journey to success.</p>

          <div className="btn-box">
            <button className="cti" onClick={handleHireMeClick}>
              <div className="CTI">
                Hire Me
              </div>
            </button>
            <button className="cti" onClick={handleLetsTalkClick}>
              <div className="CTI">
                Let's Talk
              </div>
            </button>
          </div>
        </motion.div>
        <div className="home-sci">
          <a href="https://www.facebook.com/profile.php?id=61569124254801&is_tour_completed"><i className='bx bxl-facebook'></i></a>
          <a href="https://www.instagram.com/auainnovations/"><i className='bx bxl-instagram'></i></a>
          <a href="https://www.linkedin.com/company/aua-innovations/"><i className='bx bxl-linkedin'></i></a>
          <a href="https://wa.me/+923137102698"><i className='bx bxl-whatsapp'></i></a>
        </div>
      </section>
    </div>
  );
}

export default Herosection;
